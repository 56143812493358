<template>
  <div class="g-remove-check-code">
    <div class="g-remove-check-code_content"
         @keyup="fnCheckCodeKeyup"
         @keydown="fnCheckCodeKeydown"
         @paste="fnCheckCodeKeyPaste"
         @input="fnCheckCodeInputEvent"
    >
      <input :class="{'g-code-input_color': aCheckCodeInputComputed[0] !== ''}" max="9" min="0" maxlength="1"
             data-index="0" v-model.trim.number="aCheckCodeInputComputed[0]" type="number" ref="firstInputRef"/>
      <input :class="{'g-code-input_color': aCheckCodeInputComputed[1] !== ''}" max="9" min="0" maxlength="1"
             data-index="1" v-model.trim.number="aCheckCodeInputComputed[1]" type="number"/>
      <input :class="{'g-code-input_color': aCheckCodeInputComputed[2] !== ''}" max="9" min="0" maxlength="1"
             data-index="2" v-model.trim.number="aCheckCodeInputComputed[2]" type="number"/>
      <input :class="{'g-code-input_color': aCheckCodeInputComputed[3] !== ''}" max="9" min="0" maxlength="1"
             data-index="3" v-model.trim.number="aCheckCodeInputComputed[3]" type="number"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aCheckCodeInput: ['', '', '', ''], 	// 存储输入验证码内容
      aCheckCodePasteResult: [] 				// 粘贴的验证码
    }
  },
  computed: {
    // 验证码计算属性
    aCheckCodeInputComputed() {
      if (this.aCheckCodePasteResult.length === 4) {
        return this.aCheckCodePasteResult
      } else if (this.aCheckCodeInput && Array.isArray(this.aCheckCodeInput) && this.aCheckCodeInput.length === 4) {
        return this.aCheckCodeInput
      } else if (/^\d{4}$/.test(this.aCheckCodeInput.toString())) {
        return this.aCheckCodeInput.toString().split("")
      } else {
        return new Array(4)
      }
    }
  },
  watch: {
    aCheckCodeInput(val) {
      const result = val.filter((item) => {
        return item !== '' && typeof item === 'string'
      })
      if (result.length === 4) {
        this.$emit('doLogin')
      }
    }
  },
  methods: {
    // 输入验证码，更新验证码数据
    fnCheckCodeKeyup(e) {
      let index = e.target.dataset.index * 1
      let el = e.target
      // 解决输入e的问题
      el.value = el.value
          .replace(/Digit|Numpad/i, "")
          .slice(0, 1)
      if (el.value) {
        // 必须在这里赋值，否则输入框会是空值
        this.aCheckCodeInput.splice(index, 1, el.value.replace(/Digit|Numpad/i, ""))
        el.nextElementSibling && el.nextElementSibling.focus()
        if (index === 5) {
          if (this.aCheckCodeInput.join("").length === 4) document.activeElement.blur()
        }
      }
    },
    // 输入验证码，检测位置变化
    fnCheckCodeKeydown(e) {
      let index = e.target.dataset.index * 1
      let el = e.target
      if (e.key === "Backspace") {
        if (this.aCheckCodeInput[index].length > 0) {
          this.aCheckCodeInput.splice(index, 1, '')
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus()
            this.aCheckCodeInput[index - 1] = ''
          }
        }
      } else if (e.key === 'Delete') {
        if (this.aCheckCodeInput[index].length > 0) {
          this.aCheckCodeInput.splice(index, 1, '')
        } else {
          if (el.nextElementSibling) {
            el.nextElementSibling.focus()
            this.aCheckCodeInput[++index] = ''
          }
        }
      } else if (e.key === "Home") {
        el.parentElement.children[0] && el.parentElement.children[0].focus()
      } else if (e.key === "End") {
        el.parentElement.children[this.aCheckCodeInput.length - 1] &&
        el.parentElement.children[this.aCheckCodeInput.length - 1].focus()
      } else if (e.key === "ArrowLeft") {
        if (el.previousElementSibling) el.previousElementSibling.focus()
      } else if (e.key === "ArrowRight") {
        if (el.nextElementSibling) el.nextElementSibling.focus()
      }
    },
    // 输入验证码，解决一个输入框输入多个字符的问题
    fnCheckCodeInputEvent(e) {
      let index = e.target.dataset.index * 1
      let el = e.target
      el.value = el.value
          .replace(/Digit|Numpad/i, "")
          .slice(0, 1)
      this.aCheckCodeInput[index] = el.value
    },
    // 验证码粘贴
    fnCheckCodeKeyPaste(e) {
      e.clipboardData.items[0].getAsString((str) => {
        if (str.toString().length === 4) {
          this.aCheckCodePasteResult = str.split("")
          document.activeElement.blur()
          this.aCheckCodeInput = this.aCheckCodeInputComputed
          this.aCheckCodePasteResult = []
        } else {
          // 如果粘贴内容不合规，清除所有内容
          this.aCheckCodeInput = ['', '', '', '']
        }
      })
    }
  },
  mounted() {
    this.$refs.firstInputRef.focus()
  }
}
</script>
<style>
.g-remove-check-code {
  padding: 0 0.32rem;
}

.g-remove-check-code .g-remove-check-code_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.853rem 0 0.293rem;
  margin: 0 auto;
}

.g-remove-check-code .g-remove-check-code_content input {
  width: 1.333rem;
  height: 1.333rem;
  border-radius: 0.107rem;
  border: 1px solid #E1E1E1;
  text-align: center;
  outline: none;
  font-size: 0.8rem;
  font-weight: 600;
  color: #317CFF;
  -moz-appearance: textfield;
}

.g-remove-check-code .g-remove-check-code_content input.g-code-input_color {
  border-color: #366DF7;
}

.g-remove-check-code .g-remove-check-code_content input::-webkit-outer-spin-button,
.g-remove-check-code .g-remove-check-code_content input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
</style>
